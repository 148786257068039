// import About from './components/About';
import Contacts from "./components/Contacts";
import BillId from "./components/BillId";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Oferta from "./components/Oferta";
import Articles from "./components/Articles";
import Article from "./components/Article";
import ServicesLinks from "./components/ServicesLinks";
import ServiceDetail from './components/ServiceDetail';
import TonTest from "./components/TonTest";

// Импортируем TonConnectUIProvider из библиотеки
import { TonConnectUIProvider } from '@tonconnect/ui-react';

function App() {
  return (
      <TonConnectUIProvider manifestUrl="https://nakrut.im/tonconnect-manifest.json">
        {/* Все компоненты теперь имеют доступ к TonConnect */}
        <Navbar />
        <Routes>
          <Route path="/">
            <Route index element={<Hero />} />
            <Route path="servicesLinks" element={<ServicesLinks />} />
            <Route path="services" element={<Services />} >
              <Route path=":category" element={<Services />} />
            </Route>
            <Route path="/service/:id" element={<ServiceDetail />} />
            <Route path="articles" element={<Articles />} />
            <Route path="article">
              <Route path=":articleId" element={<Article />} />
            </Route>
            <Route path="oferta" element={<Oferta />} />
            {/* About на данный момент скрыт, возможно понадобится позднее */}
            {/* <Route path="about" element={<About />} /> */}
            <Route path="contacts" element={<Contacts />} />
            <Route path="order">
              <Route path=":billId" element={<BillId />} />
            </Route>
            <Route path="*" element={<Hero />} />
            <Route path="/ton-test" element={<TonTest />} /> {/* Роут для TonTest */}
          </Route>
        </Routes>
        <Footer />
      </TonConnectUIProvider>
  );
}

export default App;
