import React from 'react';
import { TonConnectButton } from '@tonconnect/ui-react';

const TonTest = () => {
    return (
        <div className="flex flex-col justify-center items-center h-screen bg-[#f5f5f5]">
            <TonConnectButton />
        </div>
    );
};

export default TonTest;
